<ng-container>
  <div
    [attr.id]="sectionId()"
    class="grid grid-cols-1 items-center md:grid-cols-6"
    [class.text-center]="titleAlignment === 'center'"
    [class.text-left]="titleAlignment === 'left'"
  >
    @if (plot) {
      <div class="mb-2xs plot-md md:col-span-full">{{ plot }}</div>
    }
    @if (title) {
      @if (suppressHTag) {
        <p
          role="heading"
          aria-level="2"
          class="inline-block heading-2xl"
          [class.md:col-span-full]="!titleButton"
          [class.md:col-span-4]="titleButton"
        >
          {{ title }}
        </p>
      } @else {
        <h2
          class="inline-block heading-2xl"
          [class.md:col-span-full]="!titleButton"
          [class.md:col-span-4]="titleButton"
        >
          {{ title }}
        </h2>
      }
    }
    @if (titleButton) {
      <div class="flew-row order-1 mt-xl flex justify-end md:order-none md:col-span-2 md:mt-0">
        <button class="btn btn-ghost" (click)="titleButtonClick.emit()" [attr.id]="titleButtonId">
          <span>{{ titleButton }}</span>
          <fa-icon class="ml-sm" [icon]="titleButtonIcon"></fa-icon>
        </button>
      </div>
    }
    @if (description) {
      <p class="mt-md whitespace-pre-wrap md:col-span-full">{{ description }}</p>
    }
  </div>
  <div [class.mt-lg]="title || plot">
    <ng-content></ng-content>
  </div>
  @if (mainCallToAction || secondaryCallToAction) {
    <div
      class="mt-4 text-center"
      [class.text-center]="actionAlignment === 'center'"
      [class.text-left]="actionAlignment === 'left'"
    >
      @if (actionHeader) {
        <div class="pb-xl font-bold">
          {{ actionHeader }}
        </div>
      }
      <div class="space-x-sm">
        @if (mainCallToAction) {
          <button class="btn btn-elevated btn-primary" (click)="mainCallToActionClick.emit(); $event.stopPropagation()">
            {{ mainCallToAction }}
          </button>
        }
        @if (secondaryCallToAction) {
          <button
            class="btn btn-elevated btn-secondary"
            (click)="secondaryCallToActionClick.emit(); $event.stopPropagation()"
          >
            {{ secondaryCallToAction }}
          </button>
        }
      </div>
    </div>
  }
</ng-container>
