import {NgModule} from '@angular/core'
import {CommonModule} from '@angular/common'
import {SliderComponent} from './slider.component'
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome'
import {SliderIndicatorComponent} from './slider-indicator/slider-indicator.component'

@NgModule({
  declarations: [SliderComponent, SliderIndicatorComponent],
  exports: [SliderComponent, SliderIndicatorComponent],
  imports: [CommonModule, FontAwesomeModule],
})
export class SliderModule {}
