import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  Output,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core'
import {faChevronLeft} from '@fortawesome/pro-solid-svg-icons/faChevronLeft'
import {faChevronRight} from '@fortawesome/pro-solid-svg-icons/faChevronRight'
import {isPlatformBrowser} from '@angular/common'
import {Subject} from 'rxjs'
import {DocumentService} from '../utils/document.service'

@Component({
  selector: 'cft-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SliderComponent implements AfterViewInit, OnDestroy {
  readonly nextArrow = faChevronRight
  readonly previousArrow = faChevronLeft

  @Input() itemSpacingPx = 16
  @Input() backButtonTitle? = 'Back'
  @Input() forwardButtonTitle? = 'Forward'
  @Input() alignCenter = true

  @Output()
  ready = new EventEmitter<SliderComponent>()
  numberOfPages!: number
  activePage = 0
  private pageWidth!: number
  private itemWidth!: number
  private numberOfItemsPerPage!: number
  private pageMargin!: number
  private maxScrollLeft!: number

  @ViewChild('slider') private readonly sliderElement!: ElementRef
  @ViewChild('itemsContainer') private readonly itemsContainer!: ElementRef

  private items!: Element[]
  private autoCalcActivePage = true
  private readonly unsubscribe$ = new Subject<void>()
  private autoCalcActivePageTimeoutRef?: ReturnType<typeof setTimeout>
  private pageOffsets: {page: number; offset: number}[] = []

  constructor(
    private readonly cdr: ChangeDetectorRef,
    private readonly documentService: DocumentService,
    @Inject(PLATFORM_ID) private platformId: Record<string, unknown>,
  ) {}

  @HostListener('window:resize')
  onResize(): void {
    // we resize the offerings section based on the height of the categories section, so we need to fire change detection
    // as soon as screen size changes.
    setTimeout(() => this.calculatePageWidth())
  }

  get sliderHtmlElement(): HTMLDivElement | undefined {
    return this.sliderElement?.nativeElement
  }

  ngAfterViewInit(): void {
    const sliderHtmlElement = this.sliderHtmlElement!
    this.items = Array.from(this.itemsContainer.nativeElement.children)
    setTimeout(() => {
      this.calculatePageWidth()
      this.ready.emit(this)
    })

    // mark for check on scroll to update the controls
    sliderHtmlElement.addEventListener('scroll', this.autoCalculateActivePage.bind(this))
  }

  calculatePageWidth(): void {
    if (!this.items?.length) return
    if (!isPlatformBrowser(this.platformId)) return

    this.itemWidth = this.items[0].getBoundingClientRect().width + this.itemSpacingPx
    this.numberOfItemsPerPage = Math.max(Math.trunc(this.sliderHtmlElement!.offsetWidth / this.itemWidth), 1)
    const numberOfPages = this.items.length / this.numberOfItemsPerPage
    if (numberOfPages % 1 > 0) {
      this.numberOfPages = Math.trunc(numberOfPages) + 1
    } else {
      this.numberOfPages = numberOfPages
    }
    this.pageWidth = this.numberOfItemsPerPage * this.itemWidth
    this.pageMargin =
      (this.sliderHtmlElement!.offsetWidth - this.itemWidth * this.numberOfItemsPerPage) / 2 - this.itemSpacingPx / 2
    this.maxScrollLeft = this.sliderHtmlElement!.scrollWidth - this.sliderHtmlElement!.offsetWidth

    this.pageOffsets = []
    for (let i = 0; i < numberOfPages; i++) {
      this.pageOffsets.push({page: i, offset: this.getPageOffset(i)})
    }

    this.cdr.markForCheck()
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next()
    this.unsubscribe$.complete()
  }

  nextPage(): void {
    this.lockAutoCalculateActivePage(() => {
      this.activePage = this.activePage + 1
      this.scroll(this.getPageOffset(this.activePage))
    })
  }

  previousPage(): void {
    this.lockAutoCalculateActivePage(() => {
      this.activePage = this.activePage - 1
      this.scroll(this.getPageOffset(this.activePage))
    })
  }

  toPage(page: number): void {
    this.lockAutoCalculateActivePage(() => {
      this.activePage = page
      this.scroll(this.getPageOffset(this.activePage))
    })
  }

  isOnFirstPage(): boolean {
    return this.activePage === 0
  }

  isOnLastPage(): boolean {
    return this.activePage === this.numberOfPages - 1
  }

  getPageOffset(page = 1): number {
    if (page === 0) return 0

    const offset = page * this.pageWidth - this.pageMargin

    if (offset > this.maxScrollLeft) {
      return this.maxScrollLeft
    }

    return offset
  }

  /**
   *  When manually navigating to a specific page, we need to switch off automatic calculation for a bit (while it scrolls)
   *  in order to avoid weird animations
   */
  private lockAutoCalculateActivePage(fn: () => void) {
    this.autoCalcActivePage = false

    fn()

    if (this.autoCalcActivePageTimeoutRef) {
      clearTimeout(this.autoCalcActivePageTimeoutRef)
    }

    this.autoCalcActivePageTimeoutRef = setTimeout(() => {
      this.autoCalcActivePage = true
    }, 1000)
  }

  private autoCalculateActivePage(): void {
    if (!this.autoCalcActivePage) return

    const scrollLeft = this.sliderHtmlElement?.scrollLeft ?? 0

    const nextPageOffset = this.pageOffsets.find(p => p.page === this.activePage + 1)
    if (scrollLeft >= (nextPageOffset?.offset || 0)) {
      this.activePage = nextPageOffset?.page || this.activePage
    }

    const previousPageOffset = this.pageOffsets.find(p => p.page === this.activePage - 1)
    if (scrollLeft <= (previousPageOffset?.offset || 0)) {
      this.activePage = previousPageOffset?.page || 0
    }

    if (this.activePage > this.numberOfPages) this.activePage = this.numberOfPages

    this.cdr.markForCheck()
  }

  private scroll(offset: number): void {
    const slides: HTMLDivElement = this.sliderElement.nativeElement
    slides.scroll({left: offset, behavior: 'smooth'})
  }
}
