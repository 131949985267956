<div class="flex items-center">
  @for (z of pageArray; track z; let i = $index) {
    <div class="cursor-pointer p-xs outline-none" (click)="selectIndicator(i)">
      <div
        style="border-radius: 50%"
        [ngClass]="{
          active: activePage === i,
          inactive: activePage !== i
        }"
      ></div>
    </div>
  }
</div>
