import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core'

@Component({
  selector: 'cft-slider-indicator',
  templateUrl: './slider-indicator.component.html',
  styleUrls: ['./slider-indicator.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SliderIndicatorComponent {
  @Input() pages = 1
  @Input() activePage = 0
  @Output() indicatorClick = new EventEmitter<number>()

  get pageArray(): void[] {
    return new Array(isFinite(this.pages) ? this.pages : 0)
  }

  selectIndicator(forPage: number): void {
    this.activePage = forPage
    this.indicatorClick.emit(forPage)
  }
}
