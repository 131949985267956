<div>
  <div #slider class="slides" [class.justify-center]="numberOfPages <= 1 && alignCenter">
    <div #itemsContainer class="slides-container flex pl-px-2">
      <ng-content></ng-content>
    </div>
  </div>
  @if (numberOfPages > 1) {
    <div class="mt-lg flex items-center justify-center md:justify-between">
      <button (click)="previousPage()" [disabled]="isOnFirstPage()" class="btn btn-ghost hidden md:block">
        <fa-icon [icon]="previousArrow"></fa-icon>
        <span class="ml-md">{{ backButtonTitle || 'Back' }}</span>
      </button>
      <cft-slider-indicator
        (indicatorClick)="toPage($event)"
        [pages]="numberOfPages"
        [activePage]="activePage"
      ></cft-slider-indicator>
      <button (click)="nextPage()" [disabled]="isOnLastPage()" class="btn btn-ghost hidden md:block">
        <span>{{ forwardButtonTitle || 'Forward' }}</span>
        <fa-icon [icon]="nextArrow" class="ml-md"></fa-icon>
      </button>
    </div>
  }
</div>
